export class MSALTokenCredential {
  constructor(instance, account, scopes) {
    this.instance = instance;
    this.account = account;
    this.scopes = scopes;
  }

  async getToken() {
    const request = {
      scopes: this.scopes,
      account: this.account,
    };

    try {
      const response = await this.instance.acquireTokenSilent(request);
      return {
        token: response.accessToken,
        expiresOnTimestamp: response.expiresOn.getTime(),
      };
    } catch (error) {
      console.error("Token acquisition failed:", error);
      return null;
    }
  }
}
