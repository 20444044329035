import React from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import ModelManager from "./ModelManager";
import "./App.css";

const App = () => {
  return (
    <MsalAuthenticationTemplate
      interactionType="redirect"
      authenticationRequest={loginRequest}
    >
      <ModelManager />
    </MsalAuthenticationTemplate>
  );
};

export default App;
