export const baseUrl = `http://dev-inference-predictor.dev-inference.aks-krc.dentbird.com/v2`;

export const checkModelStatus = async () => {
  const url = `${baseUrl}/repository/index`;
  const response = await fetch(url, {
    method: "post",
  });
  const body = await response.json();
  console.log(body);
  return body;
};

export const getLoadModelUrl = (modelName) =>
  `${baseUrl}/repository/models/${modelName}/load`;

export const loadModel = async (modelName) => {
  const url = getLoadModelUrl(modelName);
  const response = await fetch(url, {
    method: "post",
  });
  const body = await response.json();
  console.log(body);
  return body;
};

export const getUnLoadModelUrl = (modelName) =>
  `${baseUrl}/repository/models/${modelName}/unload`;

export const unloadModel = async (modelName) => {
  const url = getUnLoadModelUrl(modelName);
  const response = await fetch(url, {
    method: "post",
  });
  const body = await response.json();
  console.log(body);
  return body;
};
