import React, { useCallback } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { linter, lintGutter } from "@codemirror/lint";
import { oneDark } from "@codemirror/theme-one-dark";

const ConfigEditor = ({
  userId,
  config,
  setConfig,
  setIsConfigCorrect,
  setModelName,
}) => {
  const validateConfig = (code) => {
    const diagnostics = [];
    const lines = code.split("\n");
    const stack = [];
    const bracketPairs = { "{": "}", "[": "]" };
    const openBrackets = Object.keys(bracketPairs);
    const closeBrackets = Object.values(bracketPairs);

    let position = 0;

    lines.forEach((line, index) => {
      const trimmedLine = line.trim();

      // Check for model name
      if (
        !trimmedLine.endsWith(`_${userId}"`) &&
        trimmedLine.startsWith("name") &&
        index === 0
      ) {
        const from = position + line.indexOf(trimmedLine);
        const to = from + trimmedLine.length;
        diagnostics.push({
          from,
          to,
          severity: "error",
          message: `name must end with _${userId}`,
        });
      }

      // Check for missing colons after keys
      if (
        /^[a-zA-Z_][a-zA-Z0-9_]*\s*(?!=)/.test(trimmedLine) &&
        !trimmedLine.includes(":") &&
        !trimmedLine.includes("{") &&
        !trimmedLine.includes("[")
      ) {
        const from = position + line.indexOf(trimmedLine);
        const to = from + trimmedLine.length;
        diagnostics.push({
          from,
          to,
          severity: "error",
          message: "Missing colon after key.",
        });
      }

      // Check for commas
      if (trimmedLine.includes("}")) {
        const from = position + line.indexOf(trimmedLine);
        const to = from + trimmedLine.length;
        if (lines[index + 1].includes("{") && trimmedLine !== "},") {
          diagnostics.push({
            from,
            to,
            severity: "error",
            message: "Missing comma after shape.",
          });
        }
        if (lines[index + 1].includes("]") && trimmedLine !== "}") {
          diagnostics.push({
            from,
            to,
            severity: "error",
            message: "Last shape should not end with comma.",
          });
        }
      }

      // Check for brackets
      for (let i = 0; i < trimmedLine.length; i++) {
        const char = trimmedLine[i];
        const charPos = position + line.indexOf(trimmedLine) + i;
        if (openBrackets.includes(char)) {
          stack.push({ char, line: index, pos: charPos });
        } else if (closeBrackets.includes(char)) {
          const last = stack.pop();
          if (!last || bracketPairs[last.char] !== char) {
            diagnostics.push({
              from: charPos,
              to: charPos + 1,
              severity: "error",
              message: `Mismatched bracket: expected ${
                last ? bracketPairs[last.char] : "none"
              } but found ${char}.`,
            });
          }
        }
      }

      position += line.length + 1;
    });

    while (stack.length > 0) {
      const unmatched = stack.pop();
      diagnostics.push({
        from: unmatched.pos,
        to: unmatched.pos + 1,
        severity: "error",
        message: `Unmatched bracket: ${unmatched.char}.`,
      });
    }

    if (!diagnostics.length) {
      setIsConfigCorrect(true);
    } else {
      setIsConfigCorrect(false);
    }

    return diagnostics;
  };

  const onChange = useCallback(
    (config) => {
      setConfig(config);

      const firstLine = config.split("\n")[0];
      if (firstLine.split('"').length === 3) {
        setModelName(firstLine.split('"')[1]);
      }
    },
    [setConfig, setModelName],
  );

  const myLinter = linter((view) => {
    const code = view.state.doc.toString();
    return validateConfig(code);
  });

  return (
    <div>
      <h2>Config File Editor</h2>
      <CodeMirror
        value={config}
        height="400px"
        extensions={[myLinter, lintGutter(), oneDark]}
        onChange={onChange}
      />
    </div>
  );
};

export default ConfigEditor;
